import React from "react";
import { Redirect } from "react-router-dom";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));

// auth
const Setup = React.lazy(() => import("../pages/Setup/index"));
const Subscription = React.lazy(() => import("../pages/Subscription/index"));
const Widget = React.lazy(() => import("../pages/Setup/Widget"));
// const Entity = React.lazy(() => import("../pages/Entity/index"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Logout = React.lazy(() => import("../pages/Auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/Auth/Register"));
const VerifyUser = React.lazy(() => import("../pages/Auth/VerifyUser"));
const ActivateAccount = React.lazy(() => import("../pages/Auth/ActivateAccount"));
const ConfirmAccount = React.lazy(() => import("../pages/Auth/ConfirmAccount"));
const ResetPassword = React.lazy(() => import("../pages/Auth/resetPassword"));

// declare all routes
const authProtectedRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  // { path: "/template", component: StarterPage },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/chat" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/help", component: ForgetPassword },
  { path: "/register", component: Register },
  { path: "/activate/:token", component: ActivateAccount },
  { path: "/confirm/:token", component: ConfirmAccount },
  { path: "/reset/:token", component: ResetPassword },
  { path: "/verify/:token", component: VerifyUser },
  { path: "/verify", component: VerifyUser },
  { path: "/setup/:unique", component: Widget },
  { path: "/setup", component: Setup },
  { path: "/embed/:unique", component: Widget },
  { path: "/subscription", component: Subscription },
  { path: "/subscription/:name", component: Subscription },
  // { path: "/entity/:unique", component: Entity },
  // { path: "/entity", component: Entity },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
