import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//i18n
import i18n from '../i18n';


// falgs
import usFlag from "../assets/images/flags/us.jpg";
import spain from "../assets/images/flags/spain.jpg";
import germany from "../assets/images/flags/germany.jpg";
import italy from "../assets/images/flags/italy.jpg";
import russia from "../assets/images/flags/russia.jpg";
import france from "../assets/images/flags/france.jpg";

import { setUserLanguage } from "../redux/actions";

function LanguageSelector(props) {

    const [lng, setlng] = useState("English");

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleLangSelector = () => setDropdownOpen(!dropdownOpen);

    /* changes language according to clicked language menu item */
    const changeLanguageAction = (e, lng) => {
        e.preventDefault();
        /* set the selected language to i18n */
        i18n.changeLanguage(lng);
        props.setUserLanguage(lng);
        if (lng === "sp")
            setlng("Spanish");
        else if (lng === "gr")
            setlng("German");
        else if (lng === "rs")
            setlng("Russian");
        else if (lng === "it")
            setlng("Italian");
        else if (lng === "fr")
            setlng("French");
        else if (lng === "en")
            setlng("English");
    }

    return (
        <React.Fragment>
           <div className={dropdownOpen ? "language-switcher dropdown dropdown--open" : "language-switcher dropdown"} onClick={()=> toggleLangSelector()}>
             <div className="dropdown-handle language-switcher__handle">
               {/*<img src={usFlag} className="language-switcher__flag" alt="English" />*/}
               <i className="ri-global-line font-size-20"></i>
               <i className="mdi mdi-chevron-up dropdown-arrow"></i>
             </div>
             <div className="dropdown-content language-switcher__content">
                 <Link to="/" onClick={(e) => changeLanguageAction(e, 'en')} className={lng === "English" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={usFlag} className="language-switcher__flag" alt="English" />
               </Link>
               <Link to="/" onClick={(e) => changeLanguageAction(e, 'fr')} className={lng === "French" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={france} className="language-switcher__flag" alt="French" />
               </Link>
               <Link to="/" onClick={(e) => changeLanguageAction(e, 'gr')} className={lng === "German" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={germany} className="language-switcher__flag" alt="German" />
               </Link>
               <Link to="/" onClick={(e) => changeLanguageAction(e, 'it')} className={lng === "Italian" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={italy} className="language-switcher__flag" alt="Italian" />
               </Link>
               <Link to="/" onClick={(e) => changeLanguageAction(e, 'rs')} className={lng === "Russian" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={russia} className="language-switcher__flag" alt="Russian" />
               </Link>
               <Link to="/" onClick={(e) => changeLanguageAction(e, 'sp')} className={lng === "Spanish" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                 <img src={spain} className="language-switcher__flag" alt="Spanish" />
               </Link>
             </div>
           </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  const { language } = state.Setting;
  return { language };
};

export default connect(mapStateToProps, {
  setUserLanguage,
})(LanguageSelector);
