export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_ACTIVE_ENTITY = "SET_ACTIVE_ENTITY";
export const SET_PREVIEW = "SET_PREVIEW";
export const OPEN_USER_PROFILE_SIDEBAR = "OPEN_USER_PROFILE_SIDEBAR";
export const CLOSE_USER_PROFILE_SIDEBAR = "CLOSE_USER_PROFILE_SIDEBAR";
export const SET_CONVERSATION_NAME_IN_OPEN_CHAT =
  "SET_CONVERSATION_NAME_IN_OPEN_CHAT";
export const SET_LOAD_PROGRESS = "SET_LOAD_PROGRESS";
export const SET_RIGHT_PANE = "SET_RIGHT_PANE";
export const SET_TRUE_VIEW = "SET_TRUE_VIEW";
export const SET_TRUE_MODAL = "SET_TRUE_MODAL";
export const SET_THEME = "SET_THEME";
export const SET_TOAST = "SET_TOAST";
export const SET_SCREEN_SHARING = "SET_SCREEN_SHARING";
export const SET_SCREEN_SHARING_MODAL = "SET_SCREEN_SHARING_MODAL";
