import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//Import Components
import LeftSidebarMenu from "./LeftSidebarMenu";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    // let currentpage = this.capitalizeFirstLetter(this.props.location.pathname);
    let currentpage =
      this.props.location.pathname === "/"
        ? "Dashboard"
        : this.capitalizeFirstLetter(this.props.location.pathname);

    //set document title according to page path name
    document.title = currentpage + " " + process.env.REACT_APP_TAGLINE;
  }

  render() {
    return (
      <React.Fragment>
        <div className="layout-wrapper d-lg-flex">
          {/* left sidebar menu */}
          <LeftSidebarMenu
            routeTab={this.props.location.pathname.replace("/", "")}
          />
          {/* render page content */}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Index);
