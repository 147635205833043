import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  setActiveTab,
  setRecipient,
  activeUser,
  setRightPane,
  setTheme,
  setUserLanguage,
} from "../../redux/actions";

import { capitalizeFirstLetter, switchURL } from "../../helpers/fn";
//Import Images
// import logo from "../../assets/images/icon.svg"
import iconLight from "../../assets/images/icon-light.png";
import iconDark from "../../assets/images/icon-dark.png";

//i18n
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

// falgs
import usFlag from "../../assets/images/flags/us.jpg";
import spain from "../../assets/images/flags/spain.jpg";
import germany from "../../assets/images/flags/germany.jpg";
import italy from "../../assets/images/flags/italy.jpg";
import russia from "../../assets/images/flags/russia.jpg";
import france from "../../assets/images/flags/france.jpg";

function LeftSidebarMenu(props) {
  /* intilize t variable for multi language implementation */
  const { t } = useTranslation();

  // var x;
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);

  const [lng, setlng] = useState(localStorage.i18nextLng || "en");
  //   const [theme, setTheme] = useState(props.theme || localStorage.theme);
  const [icon, setIcon] = useState("ri-moon-clear-fill");
  const operatingSystem = window.getMobileOperatingSystem();

  // const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    let page = capitalizeFirstLetter(tab) || "Dashboard";
    let title = page + " " + process.env.REACT_APP_TAGLINE;
    switchURL(tab, title, `/${tab}`);
    switchTab(tab);
    props.setActiveTab(tab);
    props.activeUser(null);
    props.setRecipient(null);
  };

  const switchTab = (tab) => {
    switch (tab) {
      case "affiliate":
        props.setRightPane("affiliate");
        break;
      case "chat":
        props.setActiveTab("chat");
        props.setRightPane("userchat");
        break;
      case "contacts":
        props.setActiveTab("contacts");
        props.setRightPane("userchat");
        break;
      case "entities":
        props.setActiveTab("entities");
        props.setRightPane("entities");
        break;
      case "profile":
        props.setActiveTab("profile");
        props.setRightPane("userchat");
        break;
      case "reports":
        props.setActiveTab("settings");
        props.setRightPane("entities");
        break;
      case "settings":
        props.setActiveTab("settings");
        props.setRightPane("userchat");
        break;
      case "trainer":
        props.setActiveTab("settings");
        props.setRightPane("entities");
        break;
      case "calendar":
        props.setActiveTab("calendar");
        props.setRightPane("calendar");
        break;
      default:
        props.setActiveTab("chat");
        props.setRightPane("userchat");
        break;
    }
  };

  const activeTab = props.activeTab;

  useEffect(() => {
    if (props.routeTab) {
      props.setActiveTab(props.routeTab);
      switchTab(props.routeTab);
    } else {
      props.setActiveTab("chat");
    }
  }, [props.routeTab]);

  useEffect(() => {
    setlng(props.language);
  }, [props.language]);

  // useEffect(()=>{
  //   x = document.getElementsByTagName("BODY")[0].dataset.theme;
  // },[])

  useEffect(() => {
    // setTheme(props.theme);
    changeTheme(props.theme);
    changeThemeIcon(props.theme);
  }, [props.theme]);

  const changeTheme = (theme) => {
    window.setTheme(theme);
    props.setTheme(
      theme === "system"
        ? document.getElementsByTagName("BODY")[0].dataset.theme
        : theme
    );
  };

  const changeThemeIcon = (theme) => {
    if (theme === "light") {
      setIcon("ri-moon-clear-fill");
    } else {
      setIcon("ri-sun-fill");
    }
  };

  const alternate = (v = "light") => {
    switch (v) {
      case "light":
        return "dark";
      case "dark":
        return "light";
      default:
        return "light";
    }
  };

  const toggleTheme = (e) => {
    e.preventDefault();
    var x = document.getElementsByTagName("BODY")[0].dataset.theme;
    var alt = alternate(x);
    // setTheme(alt);
    changeTheme(alt);
    changeThemeIcon(alt);
    // toast.dismiss();
  };

  /* changes language according to clicked language menu item */
  const changeLanguageAction = (lng) => {
    /* set the selected language to i18n */
    i18n.changeLanguage(lng);
    props.setUserLanguage(lng);
    toast.dismiss();
    if (lng === "sp") setlng("Spanish");
    else if (lng === "gr") setlng("German");
    else if (lng === "rs") setlng("Russian");
    else if (lng === "it") setlng("Italian");
    else if (lng === "fr") setlng("French");
    else if (lng === "en") setlng("English");
  };

  return (
    <React.Fragment>
      {/*{!props.trueModal || props.trueView &&*/}
      <div className="side-menu flex-lg-column border hidden">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={iconDark}
                alt={process.env.REACT_APP_ENTITY_NAME}
                height="30"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={iconLight}
                alt={process.env.REACT_APP_ENTITY_NAME}
                height="30"
              />
            </span>
          </Link>
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <Nav
            pills
            className="side-menu-nav justify-content-center"
            role="tablist"
          >
            {props.user.affiliate ? (
              <>
                <NavItem id="affiliate" className="d-none d-lg-block">
                  <NavLink
                    id="pills-affiliate-tab"
                    className={classnames({
                      active:
                        activeTab === "affiliate" || activeTab === "affiliate",
                    })}
                    onClick={() => {
                      toggleTab("affiliate");
                    }}
                  >
                    <i className="ri-dashboard-3-fill"></i>
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip
                  target="affiliate"
                  placement={
                    operatingSystem && operatingSystem !== "unknown"
                      ? "top"
                      : "right"
                  }
                >
                  {t("Dashboard")}
                </UncontrolledTooltip>
              </>
            ) : null}
            <NavItem id="profile" className="d-none d-lg-block">
              <NavLink
                id="pills-user-tab"
                className={classnames({ active: activeTab === "profile" })}
                onClick={() => {
                  toggleTab("profile");
                }}
              >
                <i className="ri-user-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="profile"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Profile")}
            </UncontrolledTooltip>
            <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={classnames({
                  active:
                    activeTab === "dashboard" ||
                    activeTab === "chat" ||
                    !activeTab,
                })}
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-message-3-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="Chats"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Chats")}
            </UncontrolledTooltip>
            {/*<NavItem id="Groups">
                            <NavLink id="pills-groups-tab" className={classnames({ active: activeTab === 'group' })} onClick={() => { toggleTab('group'); }}>
                                <i className="ri-group-fill"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Groups" placement={operatingSystem && operatingSystem !== "unknown" ? "top" : "right"}>
                            Groups
                        </UncontrolledTooltip>*/}
            <NavItem id="Calendar">
              <NavLink
                id="pills-chat-tab"
                className={classnames({
                  active:
                    activeTab === "calendar" || !activeTab,
                })}
                onClick={() => {
                  toggleTab("calendar");
                }}
              >
                <i className="ri-calendar-todo-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="Calendar"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Calendar")}
            </UncontrolledTooltip>
            <NavItem id="Contacts">
              <NavLink
                id="pills-contacts-tab"
                className={classnames({ active: activeTab === "contacts" })}
                onClick={() => {
                  toggleTab("contacts");
                }}
              >
                <i className="ri-contacts-book-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="Contacts"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Contacts")}
            </UncontrolledTooltip>
            <NavItem id="Entities" className="d-none d-lg-block">
              <NavLink
                id="pills-setting-tab"
                className={classnames({ active: activeTab === "entities" })}
                onClick={() => {
                  toggleTab("entities");
                }}
              >
                <i className="ri-pantone-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="Entities"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Entities")}
            </UncontrolledTooltip>
            <NavItem id="Settings" className="d-none d-lg-block">
              <NavLink
                id="pills-setting-tab"
                className={classnames({ active: activeTab === "settings" })}
                onClick={() => {
                  toggleTab("settings");
                }}
              >
                <i className="ri-list-settings-fill"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip
              target="Settings"
              placement={
                operatingSystem && operatingSystem !== "unknown"
                  ? "top"
                  : "right"
              }
            >
              {t("Settings")}
            </UncontrolledTooltip>
            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none"
            >
              <DropdownToggle nav>
                {/* {props.user && props.user.image ? (
                  <img
                    src={props.user.image}
                    className="rounded-circle profile-user"
                    alt={props.user.name}
                  />
                ) : (
                  <div className="avatar-xs profile-user">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                      {props.user.name.charAt(0)}
                    </span>
                  </div>
                )} */}
                <i className="ri-menu-fill"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {props.user.affiliate ? (
                  <DropdownItem
                    onClick={() => {
                      toggleTab("affiliate");
                    }}
                  >
                    {t("Affiliate")}{" "}
                    <i className="ri-dashboard-3-line float-end text-muted"></i>
                  </DropdownItem>
                ) : null}
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  {t("Profile")}{" "}
                  <i className="ri-user-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  {t("Settings")}{" "}
                  <i className="ri-list-settings-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="/logout">
                  {t("Logout")}{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className="flex-lg-column d-none d-lg-block">
          <Nav className="side-menu-nav justify-content-center">
            <Dropdown
              nav
              isOpen={dropdownOpen2}
              className="btn-group dropup profile-user-dropdown"
              toggle={toggle2}
            >
              <DropdownToggle nav>
                <i className="ri-global-fill"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => changeLanguageAction("en")}
                  active={lng === "en" || lng === "English"}
                >
                  <img src={usFlag} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">English</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("fr")}
                  active={lng === "fr" || lng === "French"}
                >
                  <img src={france} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">French</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("gr")}
                  active={lng === "gr" || lng === "German"}
                >
                  <img src={germany} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">German</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("it")}
                  active={lng === "it" || lng === "Italian"}
                >
                  <img src={italy} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Italian</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("rs")}
                  active={lng === "rs" || lng === "Russian"}
                >
                  <img src={russia} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Russian</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("sp")}
                  active={lng === "sp" || lng === "Spanish"}
                >
                  <img src={spain} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Spanish</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <li className="nav-item">
              <NavLink
                id="light-dark"
                onClick={(e) => {
                  toggleTheme(e);
                }}
              >
                <i className={icon}></i>
              </NavLink>
              <UncontrolledTooltip
                target="light-dark"
                placement={
                  operatingSystem && operatingSystem !== "unknown"
                    ? "top"
                    : "right"
                }
              >
                {t("Dark / Light Mode")}
              </UncontrolledTooltip>
            </li>
            {/*<Dropdown nav isOpen={dropdownOpen} className="nav-item btn-group dropup profile-user-dropdown" toggle={toggle}>
                            <DropdownToggle className="nav-link" tag="a">
                                <img src={avatar1} alt="" className="profile-user rounded-circle" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { toggleTab('profile'); }}>Profile <i className="ri-profile-fill float-end text-muted"></i></DropdownItem>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Setting <i className="ri-settings-3-fill float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem href="/logout">Log out <i className="ri-logout-circle-r-fill float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>*/}
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
      {/*}*/}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.Auth;
  const { activeTab, trueView, trueModal } = state.Layout;
  const { language, theme } = state.Setting;
  return { user, activeTab, trueView, trueModal, language, theme };
  // return {
  //     ...state.Layout,
  //     ...state.Setting
  // };
};

export default connect(mapStateToProps, {
  setActiveTab,
  setRecipient,
  activeUser,
  setRightPane,
  setTheme,
  setUserLanguage,
})(LeftSidebarMenu);
