import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";

import { setUserTheme } from "../redux/actions";

function ThemeSwitcher(props) {
  const themeCheckbox = useRef();
  const [theme, setTheme] = useState(localStorage.theme || props.theme);
  const [checked, setChecked] = useState(true);
  const alternate = (v = "light") => {
    switch(v){
      case 'light':
        return 'dark';
      case 'dark':
        return 'light';
      default:
        return 'light';
    }
  }

  const toggleChecked = () => {
    setChecked(!checked);
  }

  const changeTheme = (theme) => {
    setTheme(theme);
    props.setUserTheme(theme);
    window.setTheme(theme);
  }

  const handleChange = () => {
    theme && theme === "light" ? setChecked(false) : setChecked(true);
  }

  const toggleTheme = (e) => {
    e.preventDefault();
    var x = document.getElementsByTagName("BODY")[0].dataset.theme;
    var alt = alternate(x);
    changeTheme(alt);
  }

  useEffect(()=> {
    changeTheme(theme);
    handleChange();
  },[theme]);

  useEffect(()=> {
    if (checked === true) {
      if (themeCheckbox.current.classList.contains('checked')) {
          themeCheckbox.current.classList.remove('checked');
      }
    } else {
      if (!themeCheckbox.current.classList.contains('checked')) {
          themeCheckbox.current.classList.add('checked');
      }
    }
  },[checked]);

  return (
    <React.Fragment>
      <div className={"theme"}>
        <div className="theme__switcher">
          <div className="checkbox">
            <input type="checkbox" className="checked" ref={themeCheckbox} id="theme_switcher" onChange={(e)=>handleChange()} onClick={(e)=>toggleTheme(e)}/>
            <label htmlFor="theme_switcher"></label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { theme } = state.Setting;
  return { theme };
};

export default connect(mapStateToProps, { setUserTheme })(ThemeSwitcher);
