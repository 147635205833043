import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  API_FAILED,
  VERIFY_USER,
  SET_USER_ID,
  SET_SUBSCRIPTIONS,
  SET_USER_STATUS,
} from "./constants";

import { getLoggedInUser } from "../../helpers/authUtils";

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  subscriptions: [],
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case SET_USER_STATUS:
      return { ...state, user: { ...state.user, status: action.payload || 2 } };

    case REGISTER_USER:
      return { ...state, loading: true };

    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case LOGOUT_USER:
      return { ...state, user: null };

    case SET_USER_ID:
      return { ...state, user: action.payload };

    case VERIFY_USER:
      return { ...state, user: action.payload };

    case FORGET_PASSWORD:
      return { ...state, loading: true };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };

    case API_FAILED:
      return { ...state, loading: false, error: action.payload };

    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Auth;
