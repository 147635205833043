// @flow
import {
  SET_ACTIVE_TAB,
  SET_ACTIVE_ENTITY,
  SET_PREVIEW,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  SET_LOAD_PROGRESS,
  SET_RIGHT_PANE,
  SET_TRUE_VIEW,
  SET_TRUE_MODAL,
  SET_SCREEN_SHARING,
  SET_SCREEN_SHARING_MODAL,
  SET_THEME,
  SET_TOAST,
} from "./constants";

const INIT_STATE = {
  activeTab: "chat",
  entity: "",
  userSidebar: false,
  rightPane: "userchat",
  conversationName: "",
  preview: false,
  isLoading: 0,
  trueView: null,
  trueModal: false,
  screenSharing: null,
  screenSharingModal: false,
  theme: "",
  toastID: null,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };

    case SET_TOAST:
      return {
        ...state,
        toastID: action.payload,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case SET_ACTIVE_ENTITY:
      return {
        ...state,
        entity: action.payload,
      };

    case SET_PREVIEW:
      return {
        ...state,
        preview: action.payload,
      };

    case SET_TRUE_MODAL:
      return {
        ...state,
        trueModal: action.payload,
      };

    case SET_TRUE_VIEW:
      return {
        ...state,
        trueView: action.payload,
      };

    case SET_SCREEN_SHARING:
      return {
        ...state,
        screenSharing: action.payload,
      };

    case SET_SCREEN_SHARING_MODAL:
      return {
        ...state,
        screenSharingModal: action.payload,
      };

    case SET_RIGHT_PANE:
      return {
        ...state,
        rightPane: action.payload,
      };

    case SET_LOAD_PROGRESS:
      return {
        ...state,
        isLoading: action.payload.param
          ? action.payload.payload
          : state.isLoading + action.payload.payload,
      };

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true,
      };

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false,
      };

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
