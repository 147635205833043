import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';

import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import sagas from "./sagas";
import thunk from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

export function configureStore(initialState) {
  const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares)
    ),
  );
  sagaMiddleware.run(sagas);
  return store;
}
