// @flow
import {
  SET_CURRENT_YEAR,
  SET_SELECTED_DATE,
  SET_ALL_EVENTS,
  UPDATE_EVENTS,
  DELETE_EVENT,
  CREATE_EVENT,
} from "./constants";

const d = new Date();
let year = d.getFullYear();

const INIT_STATE = {
	currentYear: year,
	selectedDate: new Date(),
	bookedEvents: []
};

const Misc = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_CURRENT_YEAR:
			return {
				...state,
				year: action.payload
			};
		case SET_SELECTED_DATE:
			return {
				...state,
				selectedDate: action.payload
			}
		case SET_ALL_EVENTS:
			return {
        ...state,
        bookedEvents: action.payload,
      };
    case CREATE_EVENT:
      if (!action.payload || !action.payload.eventdate) {
        return state;
      }

      const { eventdate, title, description, participant } = action.payload;
      const existingEvent = state.bookedEvents.find(
        (event) => event.eventdate === eventdate && event.title === title && event.description === description
      );

      if (existingEvent) {
        return state;
      }

      return {
        ...state,
        bookedEvents: [...state.bookedEvents, action.payload],
      };
		case UPDATE_EVENTS:
			var updatedEvents = state.bookedEvents.map((event) => {
        var bookedEvents = {
          ...event,
        };
        if (event.id === action.payload.id) {
          if (event.event !== action.payload.event) {
            event.event = action.payload.event;
          }
          if (event.title !== action.payload.title) {
            event.title = action.payload.title;
          }
					if (event.description !== action.payload.description) {
            event.description = action.payload.description;
          }
					if (event.status !== action.payload.status) {
            event.status = action.payload.status;
          }
					if (event.participant !== action.payload.participant.length) {
            event.participant = action.payload.participant;
          }
        }
        return bookedEvents;
      });

      return {
        ...state,
        bookedEvents: updatedEvents,
      };
		case DELETE_EVENT:
			return {
        ...state,
        bookedEvents: state.bookedEvents.filter(
          (event) => event._id !== action.payload.id
        ),
      };
		default:
			return state;
	}
};

export default Misc;
