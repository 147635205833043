import {
  SET_USER_SETTINGS,
	SET_USER_IMAGE,
	SET_USER_THEME,
	SET_USER_LANGUAGE,
	SET_SOUND_NOTIFICATION,
} from "./constants";

export const setUserSettings = (settings) => ({
    type: SET_USER_SETTINGS,
    payload : settings
});

export const setUserImage = (image) => ({
    type: SET_USER_IMAGE,
    payload : image
});

export const setUserTheme = (theme) => ({
    type: SET_USER_THEME,
    payload : theme
});

export const setUserLanguage = (lang) => ({
    type: SET_USER_LANGUAGE,
    payload : lang
});

export const setSound = (option) => ({
    type: SET_SOUND_NOTIFICATION,
    payload : option
});
