import {
  SET_ACTIVE_TAB,
  SET_ACTIVE_ENTITY,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  SET_PREVIEW,
  SET_LOAD_PROGRESS,
  SET_RIGHT_PANE,
  SET_TRUE_VIEW,
  SET_TRUE_MODAL,
  SET_SCREEN_SHARING,
  SET_SCREEN_SHARING_MODAL,
  SET_THEME,
  SET_TOAST,
} from "./constants";

export const setTheme = (payload) => ({
  type: SET_THEME,
  payload,
});

export const setToast = (toastID) => ({
  type: SET_TOAST,
  payload: toastID,
});

export const setActiveTab = (tabId) => ({
  type: SET_ACTIVE_TAB,
  payload: tabId,
});

export const setActiveEntity = (payload) => ({
  type: SET_ACTIVE_ENTITY,
  payload,
});

export const setTrueView = (payload) => ({
  type: SET_TRUE_VIEW,
  payload,
});

export const setTrueModal = (payload) => ({
  type: SET_TRUE_MODAL,
  payload,
});

export const setScreenSharing = (payload) => ({
  type: SET_SCREEN_SHARING,
  payload,
});

export const setScreenSharingModal = (payload) => ({
  type: SET_SCREEN_SHARING_MODAL,
  payload,
});

export const setPreview = (preview) => ({
  type: SET_PREVIEW,
  payload: preview,
});

export const updateLoadProgress = (payload, param) => ({
  type: SET_LOAD_PROGRESS,
  payload: {
    payload,
    param,
  },
});

export const setRightPane = (pane) => ({
  type: SET_RIGHT_PANE,
  payload: pane,
});

export const openUserSidebar = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR,
});

export const closeUserSidebar = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR,
});

export const setconversationNameInOpenChat = (conversationName) => ({
  type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  payload: conversationName,
});
