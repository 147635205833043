import {
  SET_USER_SETTINGS,
	SET_USER_IMAGE,
	SET_USER_THEME,
	SET_USER_LANGUAGE,
	SET_SOUND_NOTIFICATION,
} from "./constants";

const INIT_STATE = {
  language: "en",
  sound: false,
  theme: "system", // system || light || dark
  image: null
};



const Setting = (state = INIT_STATE, action) => {
	switch (action.type) {
    case SET_USER_SETTINGS:
      try {
        var language, sound, theme = null;
        // language = action.payload.language ? action.payload.language : localStorage.i18nextLng;
        // sound = action.payload.sound ? action.payload.sound : true;
        // theme = action.payload.theme ? action.payload.theme : "system";
        language = action.payload.language;
        sound = action.payload.sound;
        theme = action.payload.theme;
        localStorage.setItem("i18nextLng", language);
        localStorage.setItem("sound", sound);
        localStorage.setItem("theme", theme);
      } catch (e){

      }
      return {
        ...state,
        language,
        sound,
        theme,
      };

		case SET_USER_IMAGE:
			return {
				...state,
				image: action.payload
			};

    case SET_USER_THEME:
      localStorage.setItem("theme", action.payload || "light");
      return {
        ...state,
        theme: action.payload
      };

    case SET_USER_LANGUAGE:
      localStorage.setItem("i18nextLng", action.payload || "en");
      return {
        ...state,
        language: action.payload
      };

    case SET_SOUND_NOTIFICATION:
      localStorage.setItem("sound", action.payload);
      return {
        ...state,
        sound: action.payload
      };

		default:
			return state;
	}
};

export default Setting;
