import jwtDecode from "jwt-decode";
import axios from "axios";
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }

  try {
    const dt = new Date();
    dt.setHours(dt.getHours() - 12);
    const currentTime = dt / 1000;
    if (user.iat < currentTime) {
      // console.warn('access token expired');
      logoutAuthUser();
      return false;
    } else {
      // localStorage.setItem('uid', user.id);
      // console.log("access token valid");
      return true;
    }
  } catch (e) {
    // console.warn('access token expired');
    return false;
  }
};

const verifyToken = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }

  try {
    const dt = new Date();
    dt.setHours(dt.getHours() - 12);
    const currentTime = dt / 1000;
    if (user.iat < currentTime) {
      // Clear the authentication token on both domains
      logoutAuthUser();
      return false;
    } else {
      const token = localStorage.getItem("token");
      document.cookie = `token=${token}; Domain=.${process.env.REACT_APP_DOMAIN}; Path=/`;
      return true;
    }
  } catch (e) {
    return false;
  }
};

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
  localStorage.setItem("token", user.token);
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  if (token) {
    const payload = jwtDecode(token);
    return payload
      ? typeof payload == "object"
        ? payload
        : JSON.parse(payload)
      : null;
  }
};

/**
 * Verify user account activation token
 */
const verifyAuthCode = async (auth) => {
  if (auth) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      await axios
        .post(
          process.env.REACT_APP_API + "/user/verify/" + auth,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        });
    }
  }
};

/**
 * Returns the logged in user profile
 */
const getUserProfile = async () => {
  if (verifyToken()) {
    const token = localStorage.getItem("token") || "";
    const payload = getLoggedInUser();
    await axios
      .get(process.env.REACT_APP_API + "/user/" + payload.id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
};

/**
 * Add team member
 */
const addTeamMember = async () => {
  let entity = localStorage.referer;
  let auth = localStorage.auth;
  let token = localStorage.token;
  if (entity && auth && token) {
    axios
      .post(
        process.env.REACT_APP_API + `/entity/${entity}/${auth}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (res.status === "OK") {
          localStorage.removeItem("referer");
          localStorage.removeItem("auth");
        }
        return res;
      });
  }
};

/**
 * Log out the logged in user
 */
const logoutAuthUser = () => {
  // localStorage.removeItem("uid");
  // localStorage.removeItem("token");
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Domain=.${process.env.REACT_APP_DOMAIN}; Path=/`;
  localStorage.clear();
};

const doVerification = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const link = process.env.REACT_APP_BASE_URL + "/verify/[code]";
  try {
    const body = JSON.stringify({
      action: "getCode",
      email: user.email,
      name: user.name,
      app: process.env.REACT_APP_NAME,
      entity: process.env.REACT_APP_ENTITY_NAME,
      link,
    });
    axios
      .post(process.env.REACT_APP_CHROMESQ_API + "/authy/", body)
      .then((res) => {
        // console.log(res.data);
      });
  } catch (e) {
    return false;
  }
};

const getUserEmail = () => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  if (token) {
    const payload = jwtDecode(token);
    return payload ? payload.email : null;
  }
};

const validateCode = async (code, email = "") => {
  if (verifyToken()) {
    if (!email) {
      email = getUserEmail();
    }
    try {
      const body = JSON.stringify({
        action: "verify",
        app: process.env.REACT_APP_NAME,
        email,
        code,
      });
      axios
        .post(process.env.REACT_APP_CHROMESQ_API + "/authy/", body)
        .then((res) => {
          return res.result;
        });
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

export {
  isUserAuthenticated,
  setLoggedInUser,
  getLoggedInUser,
  logoutAuthUser,
  doVerification,
  validateCode,
  getUserEmail,
  verifyToken,
  getUserProfile,
  verifyAuthCode,
  addTeamMember,
};
