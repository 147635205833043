import { size } from 'lodash';
import axios from 'axios';

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {}

  if(files){
    for (let i = 0; i < files.length; i++) {
      const id = size(existingFiles) + i + 1;
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i],
          progress: 0,
          cancelSource: source,
          // status: 0
        },
      }
    }
  }

  return fileToUpload
}
