import React from 'react';
import Routes from './routes/';

//Import Scss
import "./assets/scss/themes.scss";

function App() {
  return (
    <Routes />
  );
}

export default App;
